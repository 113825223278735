html, body {
  font-family: 'Roboto', sans-serif;
}
html, body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

@media screen {
  body {
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eeeeee+0,cccccc+100 */
    background: rgb(238, 238, 238); /* Old browsers */
    background: -moz-linear-gradient(
      top,
      rgba(238, 238, 238, 1) 0%,
      rgba(204, 204, 204, 1) 100%
    ); /* FF3.6-15 */
    background: -webkit-linear-gradient(
      top,
      rgba(238, 238, 238, 1) 0%,
      rgba(204, 204, 204, 1) 100%
    ); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(
      to bottom,
      rgba(238, 238, 238, 1) 0%,
      rgba(204, 204, 204, 1) 100%
    ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eeeeee', endColorstr='#cccccc',GradientType=0 ); /* IE6-9 */
  }
}


html {
  font-size: 0.9rem;
}


@media (min-width: 1200px) {
  html {
    font-size: 1rem;
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1300px;
  }
}

button:disabled {
  cursor: not-allowed;
}

.card-body {
  padding: 0.75rem;
}

@media (min-width: 1000px) {
  .card-body {
    padding: 1rem;
  }
}

.visible.transition {
  margin-top: auto !important;
  display: inline-block !important;
  position: relative;
  top: 20%;
}

.navbar {
  background-color: #ffffff;
}

.carousel-item {
  background: no-repeat center center scroll;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.portfolio-item {
  padding: 2px !important;
  text-align: center;
}

.logo {
  /* border-radius: 10%; */
  -webkit-transition: width 0.5s; /* Safari */
  transition: width 0.5s;
  width: 180px;
}

.featurette-row {
  padding-top: 15px;
}

.carousel-indicators {
  margin-bottom: -5px;
}

.orange-card-title {
  color: #c1491d;
}
.green-card-title {
  color: #1a702f;
}
.blue-card-title {
  color: #176589;
}
.red-card-title {
  color: #8e060b;
}

.header-title {
  font-size: 2rem;
}

.header-subtitle {
  margin-top: -6px;
  font-size: 1.5rem;
}

.carousel-control-next,
.carousel-control-prev {
  width: 6%; /* override carousel arrows distance from side */
}

.nav-link {
  padding-left: 0.8rem !important;
  text-align: center;
}

.nav-tabs .nav-link {
  cursor: pointer;
}

/* Fix some styles on datepicker */
/* .react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  padding: 0px;
} */

div.react-datepicker__time-container
  div.react-datepicker__time
  div.react-datepicker__time-box,
div.react-datepicker__time-container {
  width: 100px !important;
}

.full-width {
  width: 100%;
}

.sk-cube-grid {
  width: 100px;
  height: 100px;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}

.capitalize {
  text-transform: capitalize;
}

/* image classes for user-content */
.img-full-width {
  width: 100%;
  height: auto;
}

.img-half-width {
  width: 50%;
  height: auto;
}

.img-quarter-width {
  width: 25%;
  height: auto;
}

.img-third-width {
  width: 33%;
  height: auto;
}

/* The dropdown for target on links in tinymce was opening under modal */
.tox-menu {
  z-index: 9999 !important;
}

/* Fix zooming in on input fields of ios by setting to 16px */
/*** Styles added to fix the issue with zoom in on iphone ***/
@media screen and (max-width: 500px) {
  select, textarea, input{ font-size: 16px !important; }
}
